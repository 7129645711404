import { getAuth } from 'firebase/auth'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export function useAuth () {
  const store = useStore()
  const router = useRouter()

  const logout = async () => {
    const auth = getAuth()
    await auth.signOut()
    store.commit('search/setSearchData', null)
    await router.push({ name: 'Login' })
  }

  return { logout }
}
